import "../styles/add-request.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import ServiceController from "../services/service-controller";
import UserController from "../services/user-controller";
import { Skeleton } from "primereact/skeleton";
import { PrimeIcons } from "primereact/api";
import { getToken } from "../utils/utility";
import { Button } from "primereact/button";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Toast } from "primereact/toast";

export default function AddRequest(props) {
	const { t } = useTranslation();
	const localizer = t;
	const toast = useRef(null);
	const userController = new UserController();
	const serviceController = new ServiceController();
	const [isLoading, setIsLoading] = useState(false);
	const [store, setStore] = useState({});
	const [profile, setProfile] = useState({});
	const [isSuccess, setIsSuccess] = useState(false);
	const [code, setCode] = useState("");
	const [requestDate, setRequestDate] = useState("");
	const [errors, setErrors] = useState([]);

	const getData = async function () {
		try {
			setIsLoading(true);

			let name = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				name = decodeURI(urlParams.get("name"));
			}

			if (!getToken()) {
				window.location = `/#/login?returnUrl=${encodeURI(
					"/add-request/?name=" + name
				)}`;
				return;
			}
			const profileResult = await userController.getProfile();
			if (!profileResult.value.nationalId) {
				window.location = `/#/register?returnUrl=${encodeURI(
					"/add-request/?name=" + name
				)}`;
				return;
			}
			setProfile(profileResult.value);

			const storeResult = await serviceController.getStore(name);
			if (storeResult.isSuccess === false) throw storeResult.message;
			storeResult.value.images = [
				{ imageUrl: storeResult.value.imageUrl, title: storeResult.value.name },
			];
			setStore(storeResult.value);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	const registerRequest = async function () {
		try {
			setErrors([]);
			var objErrors = [];
			if (!requestDate) objErrors.push("تاریخ درخواست وارد کنید");
			if (objErrors.length > 0) {
				setErrors(objErrors);
				return;
			}
			//
			setIsLoading(true);
			const result = await serviceController.registerRequest(
				store.storeId,
				requestDate
			);
			if (result.isSuccess === false) throw result.message;

			setCode(result.value);
			setIsSuccess(true);
		} catch (error) {
			setErrors(error.toString().split("\r\n"));
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			<Toast ref={toast} />
			{isLoading === true && (
				<>
					<Skeleton height="15rem" className="w-12"></Skeleton>
				</>
			)}

			{isLoading === false && isSuccess === false && (
				<>
					<article className="request-details">
						<h2>به نام خدا</h2>

						<h3>مدیر محترم فروشگاه/مرکز : {store.name}</h3>
						<p>با سلام و احترام</p>
						<p>
							بدینوسیله &nbsp;
							{profile.gender === true ? "آقای" : "خانم"}
							&nbsp; : &nbsp;
							{profile.fullName}
							&nbsp; &nbsp; با کد ملی : &nbsp;
							{profile.nationalId}
						</p>
						<p>
							جهت دریافت خدمات/کالا موضوع قرارداد فی مابین به آن مرکز معرفی می
							گردد.
						</p>
					</article>

					<div className="request-form">
						<label>تاریخ درخواست : </label>
						<DatePicker
							value={requestDate}
							onChange={(value) => setRequestDate(value)}
							calendar={persian}
							locale={persian_fa}
						/>
						<Button
							label="ثبت درخواست"
							onClick={() => registerRequest()}
							loading={isLoading}
						/>
					</div>
				</>
			)}

			{isLoading === false && isSuccess === true && (
				<article className="success-request">
					<div className="header">ثبت نام موفق</div>
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
							<g fill="none" stroke="#8EC343" stroke-width="2">
								<circle
									cx="36"
									cy="36"
									r="35"
									style={{
										strokeDdasharray: "240px, 240px",
										strokeDashoffset: "480px",
									}}
								></circle>
								<path
									d="M17.417,37.778l9.93,9.909l25.444-25.393"
									style={{
										strokeDasharray: "50px, 50px",
										strokeDashoffset: "0px",
									}}
								></path>
							</g>
						</svg>
						<h2>ثبت درخواست با موفقیت انجام شد</h2>
						<p>
							درخواست شما با موفقیت ثبت شد ، شماره پیگیری :
							<strong> {code}</strong>
						</p>
					</div>
				</article>
			)}

			{errors.length > 0 && (
				<div className="errors">
					{errors.map((item) => (
						<p>{item}</p>
					))}
				</div>
			)}
		</div>
	);
}
