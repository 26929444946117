import "../styles/about.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";

export default function About(props) {
	const { t } = useTranslation();
	const localizer = t;

	return (
		<div className="about-us">
			<h3>معرفی باشگاه خدمات رفاهی تاکسیرانی تهران :</h3>

			<p>
				<b>باشگاه خدمات رفاهی تاکسیرانی تهران</b> به منظور ارائه خدمات و کالاهای
				مورد نیاز تاکسیداران گرامی بصورت اختصاصی و با شرایط ویژه ایجاد گردیده
				است لذا با تعدادی از فروشگاه و مراکز ارائه خدمات مذاکرات مفصلی صورت
				پذیرفته که اطلاعات آنها متعاقبا و در سایت به اطلاع همه اعضای محترم خواهد
				رسید ، ولی امید داریم بهترین ها توسط شما تاکسیرانان گرامی به این باشگاه
				معرفی شوند ، به همین منظور قسمتی را با عنوان <b>مراکز مورد علاقه</b> در نظر
				گرفته ایم که شما میتوانید وارد صفحه مذکور شوید و با وارد کردن شماره
				موبایل و پس از احراز هویت نسبت به معرفی مرکز و فروشگاه مورد علاقه خود
				(در رسته های اختصاصی) که سابقا خاطره دریافت خدمت یا کالای با کیفیت
				همچنین برخورد خوب و مناسب را داشته اید ، معرفی نمایید .
			</p>

			<p>
				کارشناسان با توجه به اطلاعات دریافتی از شما عزیزان نسبت به تماس با آن
				فروشگاه و مرکز خدماتی در کوتاه ترین زمان اقدام و ازایشان دعوت می گردد با
				رعایت ضوابط قانونی و شرایطی در این باشگاه نسبت به ارائه خدمات و کالا
				اقدام نماید .
			</p>

			<p>
				همچنین در سایت قسمتی را با عنوان <b>فروشگاه ها و مراکز اختصاصی طرف قرارداد</b>
				در نظر گرفته ایم که صرفا به معرفی فروشگاه ها و مراکزی که اقلام و خدمات و
				کالاهای اختصاصی و ضروری مورد نیاز تاکسیداران را ارائه می نمایند ، می
				پردازد .
			</p>

			<p>
				قطعا وجود فروشگاه ها و مراکز ارائه کالا و خدمات با شرایط ویژه و به صورت
				اقساطی از جمله ی سرویس هایی است که در راستای رسالت ارائه خدمات رفاهی به
				تمامی اعضاء از اهمیت ویژه ای برخوردار است لذا تمام سعی خویش را مصروف آن
				خواهیم کرد که با مذاکرات و رایزنی های تخصصی با فروشگاه ها و مراکز ارائه
				خدمات و کالا جهت ارائه به اعضای محترم توافق گردد ، که با عنوان <b>فروشگاه
				های خرید اعتباری و اقساطی طرف قرارداد</b> در سایت تعبیه شده است ، معرفی می
				شود .
			</p>

			<p>
				<b>سایر فروشگاه ها و مراکز طرف قرارداد</b> به فروشگاه ها و مراکزی اختصاص دارد
				که کالا و خدمات را با تخفیف ویژه ای در اختیار تاکسیداران محترم قرار
				میدهند این قسمت بیشترین تغییرات را در طول زمان خدمت رسانی خواهد داشت
				زیرا هر روزه تعدادی فروشگاه و مرکز به آن اضافه خواهد شد .
			</p>

			<p>
				<b>کمپین ها</b> احتمالا جذاب ترین قسمت این سایت باشد زیرا بناست در این قسمت با
				مشارکت تمامی اعضاء و با نظر سنجی از اعضای محترم نسبت به برگذاری آن اقدام
				نماییم ، هدف از آن دریافت کالا و یا خدمات و یا امکانات تفریحی و اقامتی و
				تورهای مسافرتی و ... است که با مراکز ارائه کننده بصورت بسیار ویژه توافق
				خواهد شد ، و به اعضای شرکت کننده در کمپین اختصاص می یابد . بنابراین
				توصیه میشود در فواصل کوتاه از این قسمت از سایت دیدن نموده و در جریان
				آخرین کمپین ها قرار بگیرید .
			</p>

			<p>
				در پایان آرزوی مجموعه آف پلاس که این باشگاه را جهت خدمت رسانی ایجاد
				نموده ، اینست که با عنایت و کمک پروردگار وهمراهی و کمک همه اعضای محترم
				خاطره ی دریافت بهترین و با کیفیت ترین خدمات و کالا ها را برای تاکسیرانان
				عزیز ایجاد نماییم و در انجام این رسالت ارزشمند از همه شما تاکسرانان
				گرامی استمداد می طلبیم .
			</p>

			<p>همیشه شاد و سلامت باشید</p>
		</div>
	);
}
