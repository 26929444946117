import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
//
import "./styles/App.css";
import { getToken, setToken, subscribe } from "./utils/utility";
import Login from "./views/login";
import CallBack from "./views/call-back";
import { Route, Routes } from "react-router-dom";
import Home from "./views/home";
import Register from "./views/register";
import About from "./views/about";
import Levels from "./views/levels";
import ContactUs from "./views/contact-us";
import SectionStores from "./views/section-stores";
import Rooms from "./views/rooms";
import RoomDetails from "./views/room-details";
import AddNewQueue from "./views/add-new-queue";
import Stores from "./views/stores";
import StoreDetails from "./views/store-details";
import { PrimeIcons } from "primereact/api";
import UserController from "./services/user-controller";
import { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import SectionCredits from "./views/section-credits";
import SectionAccessories from "./views/section-accessories";
import NewStoreDetails from "./views/new-store-details";
import AddRequest from "./views/add-request";
import Admin from "./views/admin";
import Users from "./views/users";
import EditRequests from "./views/edit-requests";
import PrintRequest from "./views/print-request";
import ViewStores from "./views/view-stores";
import EditCampaigns from "./views/edit-campaigns";
import EditStores from "./views/edit-stores";

function App() {
	const token = getToken();
	const userController = new UserController();
	const [profile, setProfile] = useState({});
	const [isProfileShow, setIsProfileShow] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isStoreAdmin, setIsStoreAdmin] = useState("");
	//> getData
	const getData = async function () {
		const profileResult = await userController.getProfile();
		if (profileResult.value) {
			setProfile(profileResult.value);
			setIsAdmin(await userController.isAdminUser());
			setIsStoreAdmin(await userController.isStoreAdmin());
		}
	};

	subscribe("refreshProfile", (data) => {
		getData();
	});

	//> useEffect
	useEffect(() => {
		getData();
	}, []);

	const confirmSignOut = function () {
		setIsProfileShow(false);
		confirmDialog({
			message: "آیا مطمئن به خروج هستید؟",
			header: "تایید خروج",
			icon: "pi pi-exclamation-triangle",
			defaultFocus: "accept",
			acceptLabel: "بله",
			rejectLabel: "نه",
			accept,
			reject,
		});
	};

	const accept = function () {
		localStorage.clear();
		window.location = "/";
	};

	const reject = function () {};

	return (
		<article
			className="main"
			onClick={(e) => {
				if (!e.target.getAttribute("data-profile-menu"))
					setIsProfileShow(false);
			}}
		>
			<ConfirmDialog />
			<header>
				<div className="top-banner">
					<img src="/images/b1.jpg" />
					<div>
						<h2>باشگاه خدمات رفاهی تاکسیرانی تهران</h2>
						{/* <p>خدمات رفاهی تاکسیرانی تهران</p> */}
					</div>
					<img src="/images/iran.png" />
				</div>

				<div className="menu-bar">
					<ul className="main-menu">
						<li>
							<a href="#">خانه</a>
						</li>
						<li>
							<a href="/#/about">معرفی</a>
						</li>
						<li>
							<a href="/#/stores">فروشگاه ها</a>
						</li>
						<li>
							<a href="/#/login">عضویت</a>
						</li>
						<li>
							<a href="/#/contact-us">تماس با ما</a>
						</li>
					</ul>

					{token && (
						<>
							<div className="profile">
								<div
									onClick={() => setIsProfileShow(true)}
									data-profile-menu="true"
								>
									<a className={PrimeIcons.USER} data-profile-menu="true"></a>
									<div data-profile-menu="true">
										<span data-profile-menu="true">{profile.fullName}</span>
										<b data-profile-menu="true">{profile.mobile}</b>
									</div>
									<i
										className={PrimeIcons.ANGLE_DOWN}
										data-profile-menu="true"
									></i>
								</div>

								<ol
									data-profile-menu="true"
									className={
										isProfileShow === true
											? "profile-menu show"
											: "profile-menu"
									}
								>
									<li data-profile-menu="true">
										<a
											href="/#/register"
											data-profile-menu="true"
											onClick={() => setIsProfileShow(false)}
										>
											<i
												className={PrimeIcons.USER_EDIT}
												data-profile-menu="true"
											></i>
											<span data-profile-menu="true">ویرایش پروفایل</span>
										</a>
									</li>

									{isStoreAdmin === true && (
										<li data-profile-menu="true">
											<a
												href="/#/edit-requests?isStore=true"
												data-profile-menu="true"
												onClick={() => setIsProfileShow(false)}
											>
												<i
													className={PrimeIcons.LIST}
													data-profile-menu="true"
												></i>
												<span data-profile-menu="true">
													درخواست های فروشگاه
												</span>
											</a>
										</li>
									)}
									{isAdmin === true && (
										<li data-profile-menu="true">
											<a
												href="/#/admin"
												data-profile-menu="true"
												onClick={() => setIsProfileShow(false)}
											>
												<i
													className={PrimeIcons.LIST}
													data-profile-menu="true"
												></i>
												<span data-profile-menu="true">پنل مدیریت</span>
											</a>
										</li>
									)}
									<li data-profile-menu="true">
										<a
											href="javascript:void(0);"
											onClick={confirmSignOut}
											data-profile-menu="true"
										>
											<i
												className={PrimeIcons.SIGN_OUT}
												data-profile-menu="true"
											></i>
											<span data-profile-menu="true">خروج</span>
										</a>
									</li>
								</ol>
							</div>
						</>
					)}

					<div className="back">
						<a href="#" onClick={() => window.history.back()}>
							<i className={PrimeIcons.ANGLE_LEFT}></i>
						</a>
					</div>
				</div>
			</header>

			<div className="content">
				<Routes>
					<Route path="/" Component={Home} exact />
					<Route path="/home" Component={Home} exact />
					<Route path="/login" Component={Login} exact />
					<Route path="/register" Component={Register} exact />
					<Route path="/call-back" Component={CallBack} exact />
					<Route path="/about" Component={About} exact />
					<Route path="/levels" Component={Levels} exact />
					<Route path="/stores" Component={Stores} exact />
					<Route path="/contact-us" Component={ContactUs} exact />
					<Route
						path="/section-accessories"
						Component={SectionAccessories}
						exact
					/>
					<Route path="/section-credits" Component={SectionCredits} exact />
					<Route path="/section-stores" Component={SectionStores} exact />
					<Route path="/rooms" Component={Rooms} exact />
					<Route path="/room-details" Component={RoomDetails} exact />
					<Route path="/add-new-queue" Component={AddNewQueue} exact />
					<Route path="/store-details" Component={StoreDetails} exact />
					<Route path="/new-store-details" Component={NewStoreDetails} exact />
					<Route path="/add-request" Component={AddRequest} exact />
					<Route path="/edit-requests" Component={EditRequests} exact />
					<Route path="/view" Component={PrintRequest} exact />
					{isAdmin && (
						<>
							<Route path="/admin" Component={Admin} exact />
							<Route path="/users" Component={Users} exact />
							<Route path="/view-stores" Component={ViewStores} exact />
							<Route path="/edit-stores" Component={EditStores} exact />
							<Route path="/edit-campaigns" Component={EditCampaigns} exact />
						</>
					)}
				</Routes>
			</div>

			<footer>
				<div className="links">
					<div>
						<h2>در مورد باشگاه خدمات رفاهی تاکسیرانی تهران</h2>
						<p></p>
						<ul>
							<li>
								<a href="/#/about">چه جوری کار می کنه؟</a>
							</li>
							<li>
								<a href="/#/about">چه جوری خرید کنم؟</a>
							</li>
							<li>
								<a href="/#/about">چه جوری عضو بشم؟ّ</a>
							</li>
						</ul>
					</div>

					<div>
						<h2>دسترسی سریع</h2>
						<ul>
							<li>
								<a href="/#/stores">جستجوی فروشگاه ها</a>
							</li>
							<li>
								<a href="/#/login">عضویت</a>
							</li>
							<li>
								<a href="/#/contact-us">اطلاعات تماس</a>
							</li>
						</ul>
					</div>

					<div>
						<h2>پیوند ها</h2>
						<ul>
							<li>
								<a href="https://taxi.tehran.ir/" target="_blank">
									سازمان تاکسیرانی
								</a>
							</li>
							<li>
								<a href="https://shahrzaad.city/" target="_blank">
									اپلیکیشن شهرزاد
								</a>
							</li>
						</ul>
					</div>
				</div>

				<p>
					© 2024 کلیه حقوق این سایت متعلق به &nbsp;
					<a href="https://offplus.ir" target="_blank">
						آف پلاس
					</a>
					&nbsp; می باشد . طراحی و بهینه سازی سایت : &nbsp;
					<a href="https://ixrm.ir" target="_blank">
						شبکه سفید
					</a>
					&nbsp;
				</p>
			</footer>
		</article>
	);
}

export default App;
