import axios from "axios";
import { API_BASE_URL, getToken } from "../utils/utility";

export default function ServiceController() {
	const token = getToken();

	//> getCities
	this.getCities = async function () {
		let result = { isSuccess: true, message: "Success", value: [] };
		try {
			//> for test
			result.value = [
				{
					name: "بندر انزلی",
					imageUrl: "/images/city/bandar-anzali.jpg",
					total: 5,
				},
				{
					name: "سمنان",
					imageUrl: "/images/city/semnan.jpg",
					total: 5,
				},
				{
					name: "قم",
					imageUrl: "/images/city/qhom.jpg",
					total: 5,
				},
				{
					name: "مریوان",
					imageUrl: "/images/city/marivan.jpg",
					total: 5,
				},
				{
					name: "مشهد",
					imageUrl: "/images/city/mashhad.jpg",
					total: 5,
				},
			];
			return result;
			//<
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getRooms
	this.getRooms = async function (cityName) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			//> for test
			result.value = [
				{
					roomId: "2",
					name: "یک خوابه",
					imageUrl: "/images/room/a1.jpg",
				},
				{
					roomId: "3",
					name: "دو خوابه",
					imageUrl: "/images/room/a2.jpg",
				},
				{
					roomId: "4",
					name: "ویلایی",
					imageUrl: "/images/room/v1.jpg",
				},
			];
			return result;
			//<
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getRoomDetails
	this.getRoomDetails = async function (roomId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			//> for test
			result.value = {
				images: [
					{
						title: "تصویر 1",
						imageUrl: "/images/room-details/g1.jpg",
					},
					{
						title: "تصویر 2",
						imageUrl: "/images/room-details/g2.jpg",
					},
					{
						title: "تصویر 3",
						imageUrl: "/images/room-details/g3.jpg",
					},
					{
						title: "تصویر 4",
						imageUrl: "/images/room-details/g4.jpg",
					},
					{
						title: "تصویر 5",
						imageUrl: "/images/room-details/g5.jpg",
					},
				],
				name: "آپارتمان دو خوابه",
				city: "بندر انزلی",
				address: "آدرس ویلایی",
				latitude: 35.7665394,
				longitude: 51.4749824,
			};
			return result;
			//<
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}odata/ResourceAttributes/?name=State`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getStores
	this.getStores = async function (
		storeType,
		category,
		pageNumber,
		pagingSize
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetStores?storeType=${storeType}&category=${category}&pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getStore
	this.getStore = async function (name) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetStore?name=${name}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getRequestForPrint
	this.getRequestForPrint = async function (code) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetRequestForPrint?code=${code}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getRegions
	this.getRegions = async function (regionType, parentId) {
		let result = { isSuccess: true, message: "Success", value: [] };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetRegions?regionType=${regionType}&parentId=${parentId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> RegisterRequest
	this.registerRequest = async function (storeId,requestDate) {
		let result = { isSuccess: true, message: "Success", value: null };
		const token = getToken();
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/RegisterRequest`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					storeId: storeId,
					requestDate: new Date(requestDate),
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
}
