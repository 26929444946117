import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import ServiceController from "../services/service-controller";
import { Skeleton } from "primereact/skeleton";

export default function SectionCredits(props) {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const [stores, setStores] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const getData = async function () {
		try {
			setIsLoading(true);
			let category = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				category = urlParams.get("cat");
			}
			const storesResult = await serviceController.getStores(
				"Credit",
				category,
				1,
				50
			);
			if (storesResult.isSuccess === false) throw storesResult.message;
			setStores(storesResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	return (
		<div className="stores">
			<h2>فروشگاه های خرید اعتباری و اقساطی طرف قرارداد</h2>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
				</>
			)}
			<ul>
				{stores.map((item) => (
					<li>
						<a href={`/#/new-store-details/?name=${item.name}`}>
							<img src={item.imageUrl} />
							<span>{item.name}</span>
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
