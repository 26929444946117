import "../styles/contact-us.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import PaymentController from "../services/payment-controller";
import { useLocation } from "react-router-dom";
import { Carousel } from "primereact/carousel";

export default function ContactUs(props) {
	const { t } = useTranslation();
	const localizer = t;

	return (
		<div>
			<h3>اطلاعات تماس</h3>
			<dl
				className="contact-address dl-horizontal"
				itemprop="address"
				itemscope=""
				itemtype="https://schema.org/PostalAddress"
			>
				<dt>
					<span>آدرس: </span>
				</dt>

				<dd>
					<span itemprop="streetAddress">
						تهران، خیابان آیت الله کاشانی بعد از ستاری، روبه روی شهر کتاب,نبش
						خیابان بنفشه، پلاک 464، ساختمان نور، طبقه 5، واحد32
					</span>
				</dd>

				<dt>
					<span>تلفن: </span>
				</dt>
				<dd>
					<span itemprop="telephone">021-49208208 </span>
				</dd>
				<dt>
					<span>سامانه پیامک: </span>
				</dt>
				<dd>
					<span itemprop="telephone">100045191000 </span>
				</dd>
			</dl>
		</div>
	);
}
