import "../styles/new-store-details.css";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import ServiceController from "../services/service-controller";
import { Skeleton } from "primereact/skeleton";
import { Carousel } from "primereact/carousel";
import { Map } from "@neshan-maps-platform/ol";
import NeshanMap from "@neshan-maps-platform/react-openlayers";
import { Image } from "primereact/image";
import { PrimeIcons } from "primereact/api";

export default function NewStoreDetails(props) {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const [isLoading, setIsLoading] = useState(false);
	const [store, setStore] = useState({});
	const mapRef = useRef(null);

	const getData = async function () {
		try {
			setIsLoading(true);
			let name = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				name = urlParams.get("name");
			}
			const storeResult = await serviceController.getStore(name);
			if (storeResult.isSuccess === false) throw storeResult.message;
			storeResult.value.images = [
				{ imageUrl: storeResult.value.imageUrl, title: storeResult.value.name },
			];
			setStore(storeResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
						<Skeleton height="15rem" className="w-3"></Skeleton>
					</div>
				</>
			)}

			<div className="new-store-details">
				<aside>
					<img src={store.imageUrl} />
					<ol>
						<li>
							<a href={store.webSite} target="_blank">
								<i className={PrimeIcons.GLOBE}></i>
							</a>
						</li>
						<li>
							<a href={store.telegram} target="_blank">
								<i className={PrimeIcons.TELEGRAM}></i>
							</a>
						</li>
						<li>
							<a href={store.instagram} target="_blank">
								<i className={PrimeIcons.INSTAGRAM}></i>
							</a>
						</li>
					</ol>
				</aside>
				<section>
					<h2>{store.name} </h2>
					<h3>خدمات</h3>
					<p>{store.services}</p>
					<h3>توضیحات تکمیلی</h3>
					<p dangerouslySetInnerHTML={{__html : store.notes}}></p>
					<h3>آدرس</h3>
					<p>{store.address}</p>

					<div className="buttons">
						<a href={`tel:${store.tel}`}>تماس : {store.tel}</a>
						<a className="request" href={`/#/add-request/?name=${store.name}`}>
							درخواست معرفی
						</a>
					</div>
				</section>
			</div>
		</div>
	);
}
