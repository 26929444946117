import "../styles/register.css";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import { Toast } from "primereact/toast";
import { addLocale } from "primereact/api";
import { useNavigate } from "react-router-dom";
import { ToggleButton } from "primereact/togglebutton";
import { getToken } from "../utils/utility";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import ServiceController from "../services/service-controller";
import { InputMask } from "primereact/inputmask";
import Plate from "../components/plate";

export default function Register(props) {
	const { t } = useTranslation();
	const localizer = t;
	const toast = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);

	//
	const [mobile, setMobile] = useState(false);
	const [nationalId, setNationalId] = useState("");
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [gender, setGender] = useState(false);
	const [birthDate, setBirthDate] = useState(null);
	const [crmStateId, setCrmStateId] = useState(null);
	const [crmCityId, setCrmCityId] = useState(null);
	const [email, setEmail] = useState(null);
	const [cardNumber, setCardNumber] = useState(null);
	const [canEditNationalId, setCanEditNationalId] = useState(true);
	const [plateNumber, setPlateNumber] = useState("");
	const [validityDate, setValidityDate] = useState("");

	const [errorMessage, setErrorMessage] = useState("");
	const userController = new UserController();
	const serviceController = new ServiceController();
	const navigate = useNavigate();

	const nationalityTypes = [
		{ value: false, text: "ایرانی" },
		{ value: true, text: "غیر ایرانی" },
	];

	const genderTypes = [
		{ value: true, text: "مرد" },
		{ value: false, text: "زن" },
	];

	useEffect(() => {
		getProfile();
	}, []);

	const getProfile = async function () {
		try {
			if (window.isBusy === true) return;
			window.isBusy = true;
			setIsLoading(true);
			setErrorMessage("");

			var profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) throw profileResult.message;
			setMobile(profileResult.value.mobile);
			setNationalId(profileResult.value.nationalId);
			setFirstName(profileResult.value.firstName);
			setLastName(profileResult.value.lastName);
			setGender(profileResult.value.gender);
			setEmail(profileResult.value.email);
			setCardNumber(profileResult.value.cardNumber);
			setPlateNumber(profileResult.value.plateNumber);
			if (profileResult.value.validityDate)
				setValidityDate(new Date(profileResult.value.validityDate));
			if (profileResult.value.birthDate)
				setBirthDate(new Date(profileResult.value.birthDate));
			if (!profileResult.value.nationalId) setCanEditNationalId(true);
			else setCanEditNationalId(false);
			//
			await getStates();
			await getCities(profileResult.value.crmStateId);
			setCrmCityId(profileResult.value.crmCityId);
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
		window.isBusy = false;
	};

	const getStates = async function () {
		const statesResult = await serviceController.getRegions(1, null);
		setStates(statesResult.value);
	};

	const getCities = async function (stateId) {
		setCrmStateId(stateId);
		const citiesResult = await serviceController.getRegions(null, stateId);
		setCities(citiesResult.value);
	};

	const register = async function () {
		try {
			setIsLoading(true);
			let errors = [];
			if (!nationalId) errors.push("کد ملی وارد کنید");
			if (!firstName) errors.push("نام را وارد کنید");
			if (!lastName) errors.push("نام خانوادگی را وارد کنید");
			//if (!crmStateId) errors.push("استان را وارد کنید");
			//if (!crmCityId) errors.push("شهر را وارد کنید");
			if (!plateNumber) errors.push("شماره پلاک وارد کنید");
			if (!validityDate) errors.push("تاریخ اعتبار وارد کنید");
			if (!cardNumber) errors.push("شماره کارت را وارد کنید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت اطلاعات",
						detail: item,
					});
				});

				setIsLoading(false);
				return;
			}
			//
			const result = await userController.updateProfile(
				firstName,
				lastName,
				gender,
				birthDate,
				crmCityId,
				email,
				cardNumber.replace(/-/g, ""),
				nationalId,
				plateNumber,
				validityDate
			);
			if (result.isSuccess === false) throw result.message;
			//
			let returnUrl = null;
			if (window.location.href.indexOf("returnUrl=") > 0)
				returnUrl =  window.location.href.split("returnUrl=")[1];
			if (returnUrl) {
				navigate(returnUrl, { replace: true });
			} else {
				navigate("/", { replace: true });
			}
		} catch (error) {
			setErrorMessage(error.toString());
		}
		setIsLoading(false);
	};
	//>
	if (!getToken()) {
		window.location = "/#/login?returnUrl=/register";
		return <></>;
	}
	return (
		<article className="register">
			<Toast ref={toast} />
			<div className="header">{localizer("Register information")}</div>
			<div className="form">
				<div>
					<div>
						<label>{localizer("Mobile number")}</label>
						<InputText value={mobile} disabled className="ltr" />
					</div>
					<div>
						<label>{localizer("National code")}</label>
						<InputText
							value={nationalId}
							onChange={(e) => setNationalId(e.target.value)}
							className="ltr"
							maxLength={10}
							disabled={canEditNationalId === false}
							keyfilter="int"
							required
						/>
					</div>
				</div>

				<div>
					<div>
						<label>{localizer("First name")}</label>
						<InputText
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							required
						/>
					</div>
					<div>
						<label>{localizer("Last name")}</label>
						<InputText
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							required
						/>
					</div>
				</div>

				<div>
					<div>
						<label>{localizer("Gender")}</label>
						<Dropdown
							value={gender}
							onChange={(e) => setGender(e.target.value)}
							options={genderTypes}
							optionLabel="text"
							optionValue="value"
							required
						/>
					</div>
					<div>
						<label>{localizer("Birth date")}</label>
						<DatePicker
							value={birthDate}
							onChange={(value) => setBirthDate(value)}
							calendar={persian}
							locale={persian_fa}
						/>
					</div>
				</div>

				<div>
					<div>
						<label>{localizer("Plate number")}</label>
						<Plate
							value={plateNumber}
							onChange={(e) => setPlateNumber(e.value)}
						/>
					</div>
					<div>
						<label>{localizer("Validity date")}</label>
						<DatePicker
							value={validityDate}
							onChange={(value) => setValidityDate(value)}
							calendar={persian}
							locale={persian_fa}
						/>
					</div>
				</div>

				{/* <div>
					<div>
						<label>{localizer("State location")}</label>
						<Dropdown
							id="crmStateId"
							className="w-full"
							value={crmStateId}
							onChange={(e) => getCities(e.value)}
							options={states}
							optionLabel="name"
							optionValue="regionId"
						/>
					</div>
					<div>
						<label>{localizer("City location")}</label>
						<Dropdown
							id="crmCityId"
							className="w-full"
							value={crmCityId}
							onChange={(e) => setCrmCityId(e.value)}
							options={cities}
							optionLabel="name"
							optionValue="regionId"
							filter
							emptyMessage="هیچ شهری پیدا نشد"
						/>
					</div>
				</div> */}

				<div>
					<div>
						<label>{localizer("Email")}</label>
						<InputText
							value={email}
							keyfilter="email"
							onChange={(e) => setEmail(e.target.value)}
							className="ltr"
						/>
					</div>
					<div>
						<label>یک شماره کارت عضو شتاب وارد کنید</label>
						<InputMask
							value={cardNumber}
							className="ltr"
							keyfilter="int"
							mask="9999-9999-9999-9999"
							slotChar="#"
							required
							onChange={(e) => setCardNumber(e.target.value)}
						/>
					</div>
				</div>
			</div>
			<div>
				<div>
					<Button
						label={localizer("Register information")}
						onClick={() => register()}
						loading={isLoading}
					/>
				</div>
				<div>{errorMessage}</div>
			</div>
		</article>
	);
}
