import "../styles/print-request.css"; 
import React, { useState, useEffect, useRef } from "react";
import ServiceController from "../services/service-controller"; 
import { Skeleton } from "primereact/skeleton"; 

export default function PrintRequest(props) {
	const serviceController = new ServiceController();
	const [isLoading, setIsLoading] = useState(false);
	const [request, setRequest] = useState({});

	const getData = async function () {
		try {
			setIsLoading(true);

			let code = "";
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				code = decodeURI(urlParams.get("code"));
			}

			const requestResult = await serviceController.getRequestForPrint(code);
			if (requestResult.isSuccess === false) throw requestResult.message;
			setRequest(requestResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div>
			{isLoading === true && (
				<>
					<Skeleton height="15rem" className="w-12"></Skeleton>
				</>
			)}

			{isLoading === false && (
				<>
					<article className="request-print">
						<header>
							<img src="/images/print-header.jpg" />
						</header>
						<div>
							<h2>به نام خدا</h2>

							<h3>مدیر محترم فروشگاه/مرکز : {request.storeName}</h3>
							<p>با سلام و احترام</p>
							<p>
								بدینوسیله &nbsp;
								{request.gender === true ? "آقای" : "خانم"}
								&nbsp; : &nbsp;
								{request.fullName}
								&nbsp; &nbsp; با کد ملی : &nbsp;
								{request.nationalId}
							</p>
							<p>
								جهت دریافت خدمات/کالا موضوع قرارداد فی مابین به آن مرکز معرفی می
								گردد.
							</p>
						</div>
						<footer>
							<img src="/images/print-footer.jpg" />
						</footer>
					</article>
				</>
			)}
		</div>
	);
}
