import "../styles/view-stores.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { APP_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import Plate from "../components/plate.js";

export default function ViewStores() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [statusNotes, setStatusNotes] = useState("");
	const [searchStatus, setSearchStatus] = useState("");
	//
	const [stores, setStores] = useState([]);
	const [store, setStore] = useState({});
	//> useEffect
	useEffect(() => {
		getData();
	}, [searchStatus,first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			const storesResult = await adminController.getStoresSummary(
				searchStatus,
				Math.floor(first / 12) + 1,
				12
			);
			if (storesResult.isSuccess === false) throw storesResult.message;
			storesResult.value.map((item) => {
				item.createdOn = new Date(item.createdOn);
			});
			let lastRowNumber = first ;
			storesResult.value.map(item=> item.index = ++lastRowNumber);

			setStores(storesResult.value);
			setTotalRecords(storesResult.total); 
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> changeSearchStatus
	const changeSearchStatus = function (status) {
		setSearchStatus(status);
		getData();
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> viewStore
	const viewStore = async function (objStore) {
		try {
			setPageType("view");
			const storeResult = await adminController.getStore(objStore.storeId);
			if (storeResult.isSuccess === false) throw storeResult.message;
			setStore(storeResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateStore
	const updateStore = async function (status) {
		try {
			let errors = [];

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ویرایش مرکز / فروشگاه",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updatestoreResult = await adminController.updateStore(
				store.storeId,
				status,
				statusNotes
			);
			if (updatestoreResult.isSuccess === false)
				throw updatestoreResult.message;

			toast.current.show({
				severity: "success",
				summary: "تغییر وضعیت فروشگاه",
				detail: localizer("ویرایش فروشگاه با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> cancelStore
	const cancelStore = function () {
		setPageType("list");
	};
	//> downloadExcel
	const downloadExcel = async function () {
		try {
			setIsDownloading(true);

			let isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				isStore = urlParams.get("isStore");
			}

			const exportResult = await adminController.exportStoresSummary(
				searchStatus
			);
			if (exportResult.isSuccess === false) throw exportResult.message;
			document
				.querySelector("#excel")
				.setAttribute(
					"href",
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
						exportResult.value
				);
			document.querySelector("#excel").click();
		} catch (error) {
			alert(error.toString());
		}
		setIsDownloading(false);
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="stores">
				{pageType === "list" && (
					<>
						<ul className="status">
							<li
								className={searchStatus === "" ? "active" : ""}
								onClick={() => changeSearchStatus("")}
							>
								همه
							</li>
							<li
								className={searchStatus === "Draft" ? "active draft" : "draft"}
								onClick={() => changeSearchStatus("Draft")}
							>
								منتظر تایید
							</li>
							<li
								className={
									searchStatus === "Accept" ? "active accept" : "accept"
								}
								onClick={() => changeSearchStatus("Accept")}
							>
								تایید شده
							</li>
							<li
								className={
									searchStatus === "Reject" ? "active reject" : "reject"
								}
								onClick={() => changeSearchStatus("Reject")}
							>
								رد شده
							</li>
						</ul>
						<div>
							<Button
								label="خروجی اکسل"
								icon={PrimeIcons.FILE_EXCEL}
								onClick={() => downloadExcel()}
								loading={isDownloading}
							/>
							<a id="excel" style={{ display: "none" }} target="_blank" />
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>نام فروشگاه</th>
									<th>درخواست ها</th>
									<th>منتظر تایید</th>
									<th>تایید شده</th>
									<th>رد شده</th>
								</tr>
							</thead>
							<tbody>
								{stores.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>{item.storeName}</td>
										<td>{item.totalRequests}</td>
										<td>{item.totalWaitingRequests}</td>
										<td>{item.totalAcceptRequests}</td>
										<td>{item.totalRejectRequests}</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "view" && (
					<div className="view-details">
						<h2>مشخصات راننده</h2>
						<ul className="user-info">
							<li>
								<label>نام راننده</label>
								<strong>{store.user.fullName}</strong>
							</li>
							<li>
								<label>موبایل</label>
								<strong>{store.user.mobile}</strong>
							</li>
							<li>
								<label>کد ملی</label>
								<strong>{store.user.nationalId}</strong>
							</li>
							<li>
								<label>سن راننده</label>
								<strong>{store.user.age}</strong>
							</li>
							<li>
								<label>جنسیت</label>
								<strong>{store.user.gender === true ? "مرد" : "زن"}</strong>
							</li>
							<li>
								<label>شماره پلاک</label>
								<div className="plate-data">
									<Plate value={store.user.plateNumber} />
								</div>
							</li>
							<li>
								<label>تاریخ اعتبار</label>
								<strong>{store.user.validityDateTitle}</strong>
							</li>
						</ul>

						<h2>مشخصات فروشگاه / مرکز ارایه خدمات</h2>
						<ul>
							<li>
								<label>نام مرکز / فروشگاه</label>
								<strong>{store.store.name}</strong>
							</li>
							<li>
								<label>تلفن</label>
								<strong>{store.store.name}</strong>
							</li>
							<li>
								<label>خدمات</label>
								<strong>{store.store.services}</strong>
							</li>
							<li>
								<label>شرح</label>
								<strong>{store.store.notest}</strong>
							</li>
							<li>
								<label>آدرس</label>
								<strong>{store.store.address}</strong>
							</li>
						</ul>
						<InputTextarea
							id="statusNotes"
							className="w-full"
							value={statusNotes}
							onChange={(e) => setStatusNotes(e.target.value)}
							placeholder="توضیحات تغییر وضعیت"
						/>

						<div className="col-12 buttons">
							<Button
								label="تایید فروشگاه"
								onClick={() => updateStore("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="رد فروشگاه"
								onClick={() => updateStore("Reject")}
								severity="danger"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelStore()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
