import "../styles/edit-campaigns.css";
import "../styles/form.css";
import { useTranslation } from "react-i18next";
import AdminController from "../services/admin-controller.js";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { API_BASE_URL, APP_BASE_URL, getToken } from "../utils/utility.js";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { PrimeIcons } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Editor } from "primereact/editor";

export default function EditCampaigns() {
	const { t } = useTranslation();
	const localizer = t;
	const adminController = new AdminController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	const [isDownloading, setIsDownloading] = useState(0);
	//
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("list");
	const [searchStatus, setSearchStatus] = useState("");
	//
	const [campaigns, setCampaigns] = useState([]);
	const [images, setImages] = useState([]);
	const [campaignId, setCampaignId] = useState(null);
	const [companyName, setCompanyName] = useState(null);
	const [title, setTitle] = useState(null);
	const [summary, setSummary] = useState(null);
	const [notes, setNotes] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [status, setStatus] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	//>
	const statuses = [
		{ label: "منتظر انتشار", value: "Draft" },
		{ label: "منتشر شده", value: "Published" },
		{ label: "منقضی شده", value: "Expired" },
	];
	//> useEffect
	useEffect(() => {
		getData();
	}, [searchStatus,first]);
	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);

			const campaignsResult = await adminController.getCampaigns(
				searchStatus,
				Math.floor(first / 12) + 1,
				12
			);
			if (campaignsResult.isSuccess === false) throw campaignsResult.message;

			campaignsResult.value.map((item) => {
				item.startDate = new Date(item.startDate);
				item.endDate = new Date(item.endDate);
			});

			let lastRowNumber = first ;
			campaignsResult.value.map(item=> item.index = ++lastRowNumber);

			setCampaigns(campaignsResult.value);
			setTotalRecords(campaignsResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};
	//> changeSearchStatus
	const changeSearchStatus = function (status) {
		setSearchStatus(status);
		getData();
	};
	//> onPageChange
	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};
	//> resetCampaign
	const resetCampaign = async function () {
		try {
			setPageType("edit");

			setCampaignId("00000000-0000-0000-0000-000000000000");
			setCompanyName("");
			setTitle("");
			setSummary("");
			setNotes("");
			setStartDate(null);
			setEndDate(null);
			setStatus("Draft");
			setImages([]);
		} catch (error) {
			alert(error.toString());
		}
	};

	//> editCampaign
	const editCampaign = async function (objCampaign) {
		try {
			setPageType("edit");

			setCampaignId(objCampaign.campaignId);
			setCompanyName(objCampaign.companyName);
			setTitle(objCampaign.title);
			setSummary(objCampaign.summary);
			setNotes(objCampaign.notes);
			setStartDate(objCampaign.startDate);
			setEndDate(objCampaign.endDate);
			setStatus(objCampaign.status);
			setImages(objCampaign.images);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> updateCampaign
	const updateCampaign = async function () {
		try {
			let errors = [];

			if (!companyName) errors.push("نام شرکت را وارد نمایید");
			if (!title) errors.push("عنوان کمپین را وارد نمایید");
			if (!startDate) errors.push("تاریخ شروع را وارد نمایید");
			if (!endDate) errors.push("تاریخ پایان را وارد نمایید");

			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: "ثبت کمپین",
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var updateCampaignResult = await adminController.updateCampaign(
				campaignId,
				companyName,
				title,
				summary,
				notes,
				new Date(startDate),
				new Date(endDate),
				status
			);
			if (updateCampaignResult.isSuccess === false)
				throw updateCampaignResult.message;

			toast.current.show({
				severity: "success",
				summary: "ثبت کمپین",
				detail: localizer("ثبت اطلاعات کمپین با موفقیت انجام شد"),
			});
			setPageType("list");
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//> uploadFile
	const selectFileForUpload = function () {
		document.querySelector("#frm_fileImage").click();
	};
	//> uploadFile
	const uploadFile = async function () {
		try {
			setIsUploading(true);
			var uploadResult = await adminController.addCampaignImage(
				campaignId,
				document.querySelector("#frm_fileImage").files[0]
			);
			if (uploadResult.isSuccess === false) throw uploadResult.message;
			let oldImages = images;
			images.push({
				fileId: uploadResult.value,
				imageUrl: `File/Thumbnail?fileId=${uploadResult.value}&w=150&h=150`,
			});
			setImages(oldImages);
		} catch (error) {
			alert(error.toString());
		}
		setIsUploading(false);
	};

	//> cancelCampaign
	const cancelCampaign = function () {
		setPageType("list");
	};
	//> downloadExcel
	const downloadExcel = async function () {
		try {
			setIsDownloading(true);

			let isStore = false;
			if (window.location.href.indexOf("?") > 0) {
				let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
				isStore = urlParams.get("isStore");
			}

			const exportResult = await adminController.exportCampaigns(
				isStore,
				searchStatus
			);
			if (exportResult.isSuccess === false) throw exportResult.message;
			document
				.querySelector("#excel")
				.setAttribute(
					"href",
					"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
						exportResult.value
				);
			document.querySelector("#excel").click();
		} catch (error) {
			alert(error.toString());
		}
		setIsDownloading(false);
	};

	//>
	if (!getToken()) {
		window.location = "/#/login";
		return;
	}
	return (
		<>
			<Toast ref={toast} position="bottom-left" life={1500} />
			<article className="campaigns">
				{pageType === "list" && (
					<>
						<ul className="status">
							<li
								className={searchStatus === "" ? "active" : ""}
								onClick={() => changeSearchStatus("")}
							>
								همه
							</li>
							<li
								className={searchStatus === "Draft" ? "active draft" : "draft"}
								onClick={() => changeSearchStatus("Draft")}
							>
								در صف انتشار
							</li>
							<li
								className={
									searchStatus === "Published" ? "active accept" : "accept"
								}
								onClick={() => changeSearchStatus("Accept")}
							>
								منتشر شده
							</li>
							<li
								className={
									searchStatus === "Expired" ? "active reject" : "reject"
								}
								onClick={() => changeSearchStatus("Reject")}
							>
								منقضی شده
							</li>
						</ul>
						<div>
							<Button
								label="ثبت کمپین جدید"
								icon={PrimeIcons.PLUS_CIRCLE}
								onClick={() => resetCampaign()}
							/>
							<Button
								label="خروجی اکسل"
								icon={PrimeIcons.FILE_EXCEL}
								onClick={() => downloadExcel()}
								loading={isDownloading}
							/>
							<a id="excel" style={{ display: "none" }} target="_blank" />
						</div>
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>کد کمپین</th>
									<th>عنوان</th>
									<th>نام شرکت</th>
									<th>تاریخ شروع</th>
									<th>تاریخ پایان</th>
								</tr>
							</thead>
							<tbody>
								{campaigns.map((item) => (
									<tr data-status={item.status}>
										<td>{item.index}</td>
										<td>
											<a
												href="javascript:void(0);"
												onClick={() => editCampaign(item)}
											>
												{item.code}
											</a>
										</td>
										<td>{item.title}</td>
										<td>{item.companyName}</td>
										<td>
											{item.startDate.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
										<td>
											{item.endDate.toLocaleString("fa-IR", {
												dateStyle: "short",
											})}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Paginator
							first={first}
							rows={rows}
							totalRecords={totalRecords}
							onPageChange={onPageChange}
						/>
					</>
				)}

				{pageType === "edit" && (
					<div className="form">
						<div>
							<div>
								<label>نام کمپین</label>
								<InputText
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									required
								/>
							</div>
							<div>
								<label>نام شرکت</label>
								<InputText
									value={companyName}
									onChange={(e) => setCompanyName(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>خلاصه</label>
								<InputText
									value={summary}
									onChange={(e) => setSummary(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="full">
							<div>
								<label>شرح</label>
								<Editor value={notes} onTextChange={(e) => setNotes(e.htmlValue)} style={{ height: '320px' }} />
							</div>
						</div>
						<div>
							<div>
								<label>تاریخ انتشار</label>
								<DatePicker
									value={startDate}
									onChange={(value) => setStartDate(value)}
									calendar={persian}
									locale={persian_fa}
								/>
							</div>
							<div>
								<label>تاریخ پایان</label>
								<DatePicker
									value={endDate}
									onChange={(value) => setEndDate(value)}
									calendar={persian}
									locale={persian_fa}
								/>
							</div>
						</div>

						<div>
							<div>
								<label>وضعیت</label>
								<Dropdown
									options={statuses}
									value={status}
									onChange={(e) => setStatus(e.value)}
								/> 
							</div>
						</div>

						<div>
							<input
								type="file"
								accept="images"
								id="frm_fileImage"
								onChange={() => uploadFile()}
							/>
							<ul className="images">
								<li onClick={() => selectFileForUpload()}>
									{isUploading === true && <Skeleton></Skeleton>}
									{isUploading === false && (
										<i className={PrimeIcons.PLUS_CIRCLE}></i>
									)}
								</li>
								{images.map((item) => (
									<li>
										<img src={API_BASE_URL + item.imageUrl} />
										<a className={PrimeIcons.TRASH}  ></a>
									</li>
								))}
							</ul>
						</div>

						<div className="buttons">
							<Button
								label="ثبت کمپین"
								onClick={() => updateCampaign("Confirm")}
								severity="success"
								icon={PrimeIcons.SAVE}
								loading={isSaving}
							/>
							<Button
								label="انصراف"
								onClick={() => cancelCampaign()}
								severity="default"
								loading={isSaving}
							/>
						</div>
					</div>
				)}
			</article>
		</>
	);
}
